<template>
<div>
  <div class="container-fluid fondo2">
    <div class="row pt-3 ml-0" style="top:70px; width:100%;">
      <div class="col col-1 col-lg-3 col-xs-12 col-md-1 col-md-1 p-0"></div>

      <div class="col col-10 col-lg-6 col-xs-12 col-md-10 col-md-10 p-0">
        <b-card :header="titulo" header-tag="h4" >
          <b-card-text class="textoCarta" style="text-align:justify!important;">
            <p class="texto pt-4">{{texto1}}</p>
            <p class="texto pt-4">{{texto2}}</p>    
            <p class="texto pt-4">{{texto3}}</p>    
            <p class="texto pt-4">{{texto4}}</p>
          </b-card-text>
            <template #footer>
              <b-icon icon="person-lines-fill" scale="2.1" aria-hidden="true"></b-icon>
            </template>
          </b-card>
        <b-card-group deck class="pt-3 pb-3">
          <b-card
            header="Misión"
            header-tag="h4"
          >
            <b-card-text class="textoCarta" style="text-align:justify!important;">{{textoMision}}</b-card-text>
            <template #footer>
              <b-icon icon="check-circle-fill" scale="2" aria-hidden="true"></b-icon>
            </template>
          </b-card>

          <b-card
            header="Visión"
            header-tag="h4"
          >
            <b-card-text class="textoCarta" style="text-align:justify!important;">{{textoVision}}</b-card-text>
            <template #footer>
              <b-icon icon="eye-fill" scale="2.3" aria-hidden="true"></b-icon>
            </template>
          </b-card>
        </b-card-group>   
      </div>

      <div class="col col-1 col-lg-3 col-xs-12 col-md-1 col-md-1 p-0"></div>
    </div>
  </div>
</div>   
</template>

<script>
import Nav from '../components/navegador'
export default {
  name: 'Home', 
  data(){
    return{
      textoMision:'Desarrollar e integrar soluciones a la medida que permitan a nuestros clientes optimizar sus procesos productivos de forma eficiente, segura, sustentable y de calidad.',
      textoVision:'Ser una empresa líder en el desarrollo de soluciones integrales de ingeniería para la industria, estableciendo relaciones duraderas y de confianza con nuestros clientes logrando un reconocimiento y prestigio sostenible a través del tiempo.',
      titulo: 'Nuestra Política',
      texto1:"Somos una empresa especializada en la generación de soluciones integrales en el diseño, fabricación, implementación y mantenimiento para la indústria, comprometida con la mejora continua de nuestros procesos.",
      texto2:"Nuestro quehacer diario está basado en satisfacer y superar las necesidades y expectativas de las partes interesadas, entregando bienes y servicios que cumplan con los requisitos de calidad, medio ambiente, seguridad y salud ocupacional, incluyendo los requisitos legales y reglamentarios pertinentes.",
      texto3:"Hemos establecido un compromiso transversal con el medio ambiente controlando los aspectos ambientales inherentes a nuestras actividades.",
      texto4:"Brindamos un ambiente de seguridad y salud ocupacional a nuestros trabajadores y partes interesadas, promoviendo en forma constante el auto cuidado en función de la prevención de lesiones y enfermedades profesionales.",
    }
  },
  components: {
    Nav
  }
}
</script>

<style>
 .col, .row {
   background: transparent!important;
 }
.fondo{
  background: url(../assets/1.jpg) center center;
  background-size: cover;
}

img{
  width: 100%;
}
.titulo{
  font-size:50px; color: #FFF; text-align:left; 
}
.texto{
  font-size:25px; color: #FFF; text-align: justify;
}
h4.card-header{
  background-color: rgba(45, 102, 207, 0.9)!important;color: #FFF;
}
div.card{
  background-color: #000000b1!important;color: #FFF;
}
</style>